import { FC, MouseEvent, useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Button, Modal, notification, Result, Space, Table } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

import styles from '../../pages/Patient/Patient.module.scss';

import useStore from '../../hooks/useStore';
import { IPatient, IPatientStudy, IStudyDownload } from '../../store/patient';
import { baseurl } from '../../api/baseurl';
import { downloadFile } from '../../utils/commonHelpers';
import { FilesApi } from '../../api/files';
import { LoadingStatus } from '../../store/types';
import { Loader, Share, StudyFile } from '../index';
import { StudyRows } from '../../pages/Patient/components';
import { getAuthToken } from '../../utils/tokenHelpers';

interface StudiesProps {
  sharing?: boolean;
}

const Studies: FC<StudiesProps> = ({ sharing = true }) => {
  const history = useHistory();
  const location = useLocation();

  const { patientStore, cashFiles } = useStore();

  const [downloadFileLoading, setDownloadFileLoading] = useState<string | null>(
    null
  );
  const [shareModal, setShareModal] =
    useState<{ type: 'study' | 'patient'; objectUUID: string }>();

  const patientShareClick =
    (patient: IPatient) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setShareModal({ type: 'patient', objectUUID: patient.patientUUID });
    };

  const studyShareClick = (studyUUID: string) => {
    setShareModal({ type: 'study', objectUUID: studyUUID });
  };

  const columns = [
    {
      title: 'ПІБ',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, patient: IPatient) =>
        sharing ? (
          <Space>
            <strong>{text}</strong>
            <Button
              icon={<ExportOutlined rev={''} />}
              onClick={patientShareClick(patient)}
            />
          </Space>
        ) : (
          <strong>{text}</strong>
        ),
    },
    {
      title: 'Дата народження',
      dataIndex: 'birthDate',
      key: 'birthDate',
      render: (text: string) => <strong>{text}</strong>,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (text: string) => <strong>{text}</strong>,
    },
  ];

  const handleFileShow = async (studyUUID: string, fileUUID: string) => {
    if (location.pathname.includes('/sharedStudies')) {
      history.push(
        `/sharedStudies/${studyUUID}/file/${fileUUID}?token=${
          getAuthToken().token
        }`
      );
      return;
    }
    history.push(`/studies/${studyUUID}/file/${fileUUID}`);
  };

  const handleFilePrepare = async (studyUUID: string) => {
    await patientStore.buildStudyImage(studyUUID);
  };

  const handleFileDownload = async (
    studyName: string,
    studyUUID: string,
    download: IStudyDownload
  ) => {
    try {
      if (download.storage === 'local') {
        window.location.href = baseurl + '/studies/local/' + download.fileUUID;
        return;
      }
      setDownloadFileLoading(download.fileUUID);
      let file;
      file = cashFiles.getFile(download.fileUUID);
      if (file) {
        downloadFile(file.blobUrl, file.name);
        return;
      }
      const { url, filename } = await FilesApi.getFileById(
        studyUUID,
        download.fileUUID
      );
      cashFiles.addFile({
        serverUrl: download.fileUUID,
        blobUrl: url,
        name: filename,
      });
      downloadFile(url, filename);
    } catch (e) {
      console.log(e);
      notification.error({
        message: 'Файл недоступний, зверніться до діагностичного центру',
      });
    } finally {
      setDownloadFileLoading(null);
    }
  };

  const checkStudyImage = async (study: IPatientStudy) => {
    const isoFile = study.downloads.find(
      (download) => download.type === 'Образ диску'
    );
    if (
      isoFile &&
      isoFile.fileUUID === 'checkStudyImage' &&
      !isoFile.isoStatus
    ) {
      await patientStore.checkFileStatus(study.studyUUID);
    }
  };

  useEffect(() => {
    if (patientStore.patients[0]?.visits[0]) {
      checkStudyImage(patientStore.patients[0].visits[0]);
    }
  }, [patientStore.patients[0]]);
  const shareObject =
    shareModal &&
    patientStore.shares.find((s) => s.objectUUID === shareModal.objectUUID);

  const shared =
    shareObject &&
    (!shareObject.expires || new Date(shareObject.expires) > new Date());

  return (
    <>
      {patientStore.getPatientsLoadingStatus === LoadingStatus.LOADING ? (
        <Loader />
      ) : (
        <>
          {patientStore.getPatientsLoadingStatus !== LoadingStatus.ERROR &&
          patientStore.patients ? (
            <>
              <Table
                className={styles.table}
                size={isMobile ? 'small' : undefined}
                dataSource={toJS(patientStore.patients).map((s) => ({
                  ...s,
                  key: s.patientID,
                }))}
                expandedRowRender={StudyRows(
                  sharing,
                  studyShareClick,
                  handleFileShow,
                  handleFileDownload,
                  downloadFileLoading,
                  checkStudyImage,
                  handleFilePrepare
                )}
                defaultExpandedRowKeys={[patientStore.patients[0]?.patientID]}
                expandRowByClick
                columns={columns}
                pagination={false}
              />
            </>
          ) : (
            <Result
              status="error"
              subTitle={patientStore.getPatientError || 'Виникла помилка'}
            />
          )}
        </>
      )}
      <Route
        exact
        path={[
          '/studies/:studyUUID/file/:fileUUID',
          '/sharedStudies/:studyUUID/file/:fileUUID',
        ]}
        component={StudyFile}
      />
      {sharing && (
        <Modal
          visible={!!shareModal}
          destroyOnClose
          footer={false}
          onCancel={() => setShareModal(undefined)}
        >
          {shareModal && (
            <Share
              type={shareModal.type}
              objectUUID={shareModal.objectUUID}
              shared={!!shared}
              expires={shareObject?.expires}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default observer(Studies);
