import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import format from 'date-fns/format';

import { Button, Select, Space } from 'antd';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';

import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';
import TextArea from 'antd/lib/input/TextArea';

interface ShareProps {
  type: 'patient' | 'study';
  objectUUID: string;
  shared: boolean;
  expires?: string | null;
}

const { Option } = Select;

const Share: FC<ShareProps> = ({ shared, objectUUID, type, expires }) => {
  const { patientStore } = useStore();

  const [days, setDays] = useState<7 | 30 | 90 | number>(30);
  const [copiedMessage, setCopiedMessage] = useState(false);

  useEffect(() => {
    if (!link) {
      return;
    }

    handleCopy();
  }, []);

  const handleAllow = async () => {
    await patientStore.share({
      operation: 'create',
      expires: days,
      objectUUID,
      objectType: type,
    });

    displayCopiedMessage();
  };

  const handleDeny = async () => {
    await patientStore.share({
      operation: 'deny',
      expires: days,
      objectUUID,
      objectType: type,
    });
  };

  const displayCopiedMessage = () => {
    setCopiedMessage(true);

    setTimeout(() => {
      setCopiedMessage(false);
    }, 3000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    displayCopiedMessage();
  };

  const share = patientStore.shares.find((s) => s.objectUUID === objectUUID);
  const token = share?.token;
  const link =
    shared && token
      ? `${window.location.origin}/sharedStudies?token=${token}`
      : '';

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: 30 }}>
      <div style={{ display: 'flex', gap: 10 }}>
        <Button
          style={{ flexGrow: 1 }}
          disabled={shared}
          loading={
            !shared && patientStore.shareLoadingStatus === LoadingStatus.LOADING
          }
          onClick={handleAllow}
        >
          Дозволити
        </Button>
        <Button
          style={{ flexGrow: 1 }}
          disabled={!shared}
          loading={
            shared && patientStore.shareLoadingStatus === LoadingStatus.LOADING
          }
          onClick={handleDeny}
        >
          Заборонити
        </Button>
      </div>
      <Space>
        {shared ? (
          expires ? (
            'Доступ буде прибрано ' +
            format(new Date(expires), 'yyyy.MM.dd HH:mm')
          ) : (
            'Доступ надано безстроково'
          )
        ) : (
          <>
            Автоматично прибрати доступ через{' '}
            <Select
              disabled={shared}
              style={{ width: 60 }}
              value={days}
              onSelect={setDays}
            >
              <Option value={0}>∞</Option>
              <Option value={7}>7</Option>
              <Option value={30}>30</Option>
              <Option value={90}>90</Option>
            </Select>
            днів
          </>
        )}
      </Space>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        Посилання:
        <TextArea style={{ flexGrow: 1 }} autoSize value={link} />
        <Button
          disabled={!shared}
          icon={<CopyOutlined rev={''} />}
          onClick={handleCopy}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          opacity: !copiedMessage ? '0' : '1',
          transition: 'all .5s',
          visibility: !copiedMessage ? 'hidden' : 'visible',
        }}
      >
        <CheckCircleOutlined rev={''} style={{ color: 'rgb(22, 119, 255)' }} />{' '}
        Посилання скопійовано
      </div>
    </Space>
  );
};

export default observer(Share);
